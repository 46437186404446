.AppLayout {
    background-color: transparent;

    :global(.ant-pagination) {
        li {
            border-radius: @border-radius-base-x2;
        }
    }

    :global(.ant-pagination-prev),
    :global(.ant-pagination-next) {
        button {
            border-radius: @border-radius-base-x2;
        }
    }

    :global(.ant-tabs-nav) {
        margin-bottom: -1px !important;
    }

    :global(.ant-tabs-top > .ant-tabs-nav::before) {
        border-bottom: 0px solid #f0f0f0 !important;
    }

    :global(.ant-tabs-tab) {
        border-radius: @border-radius-base-x2 @border-radius-base-x2 0 0 !important;
    }

    :global(.ant-tabs-content-holder) {
        border-radius: 0 @border-radius-base-x2 @border-radius-base-x2
            @border-radius-base-x2;
    }

    :global(.ant-select-selector) {
        border-radius: @border-radius-base-x2 !important;
    }

    :global(.ant-tag) {
        margin-bottom: 0.2rem;
    }

    :global(.ant-empty) {
        margin-top: 50px;
        margin-bottom: 50px;
        @media (max-width: 570px) {
            margin-top: 80px;
        }
    }

    :global(.ant-table-cell .ant-empty) {
        position: unset !important;
        top: unset !important;
        left: unset !important;
        transform: unset !important;
    }

    height: 100%;
}

:global(.ant-dropdown-menu),
:global(.ant-select-dropdown),
:global(.ant-notification-notice) {
    border-radius: @border-radius-base-x2 !important;
}

:global(.ant-dropdown-menu) > *:first-child {
    margin-top: 10px;
}

:global(.ant-dropdown-menu) > *:last-child {
    margin-bottom: 10px;
}

:global(.ant-alert) {
    border-radius: @border-radius-base-x12 !important;
}

:global(.ant-input-number-handler-wrap) {
    border-radius: 0 @border-radius-base-x2 @border-radius-base-x2 0 !important;
    margin-right: @margin-xss;
}

:global(.ant-steps-item-finish .ant-steps-item-icon) {
    border-color: @stepsItemIconFinishColor !important;

    span {
        color: @stepsItemIconFinishColor !important;
    }
}

.AppLayoutContentFeedback {
    margin-left: 20px;
    margin-bottom: 20px;
}

.AppLayoutContentWrap {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.IconTextMargin4 {
    margin-left: 4px;
}

@hack: true; @import "/src/src/antd-default-theme.less";