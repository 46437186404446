.AppGridTable {
    width: 100%;
    background: @ccxWhite;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-x: auto;
}

.AppGridTable > * {
    flex-basis: '100%';
}

.AppGridTableHeader {
    font-size: 14px;
    color: @ccxGridTableHeaderTextColor;
    overflow-x: hidden;
}

.AppGridTableHeaderCol {
    white-space: nowrap;
    padding: 0 16px;
}
.AppGridTableHeaderCol:not(:last-child) {
    min-width: 150px;
}

.AppGridTableDataRow {
    background: @ccxWhite;
    box-sizing: border-box;
    border-radius: @border-radius-base-x2;
    padding: 8px 0;
    display: flex;
    min-height: 96px;
}

.AppGridTableDataRowNormal {
    border: 1px solid @ccxGridTableRowBorderColor;
}

.AppGridTableDataRowAlert {
    border: 1px solid @ccxRed;
}

.AppGridTableDataRow > *:last-child {
    border-right: none;
    padding: 0;
}

.AppGridTableDataRowDisabled {
    opacity: 0.5;
}

.AppGridTableDataCol {
    border-right: 1px solid @ccxGridTableRowBorderColor;
    padding: 0 16px;
    display: flex;
    align-items: center;
}

.AppGridTableDataCol:not(:last-child) {
    min-width: 180px;
}

.AppGridTableDataColDisabled {
    opacity: 0.85;
}

.AppGridTableDataColCenter {
    justify-content: center;
}

.AppGridTableDataColStart {
    justify-content: flex-start;
}

.AppGridTableDataColEnd {
    justify-content: center;
}

.AppGridTableDataRow > *:last-child {
    border-right: none;
    padding: 0;
}

@media (max-width: 768px) {
    .AppGridTable {
        gap: 4px;
    }

    .AppGridTableHeaderCol,
    .AppGridTableDataCol {
        padding: 0 8px;
    }
    .AppGridTableHeaderCol {
        display: inline-block;
        min-width: 80px;
    }

    .AppGridTableDataRow {
        min-height: auto;
    }
}

@media (max-width: 1130px) {
    .AppGridTableContainer {
        width: calc(100vw - 40px);
        overflow-x: scroll;
    }

    .AppGridTable {
        min-width: 1090px;
    }
}

@media (max-width: 1160px) {
    .AppGridTableSmallContainer {
        width: calc(100vw - 90px);
        overflow-x: scroll;

        .AppGridTable {
            min-width: 1100px;
        }
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";