.IncrementalRadioOptions > *:first-child {
    border-radius: @border-radius-base-x2 0 0 @border-radius-base-x2;
}

.IncrementalRadioOptions > *:last-child {
    border-radius: 0 @border-radius-base-x2 @border-radius-base-x2 0;
}

.AppFormSelect {
    :global(.ant-select-selector) {
        border-radius: @border-radius-base-x2 !important;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";