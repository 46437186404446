@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700;900&display=swap');

:root {
    --gradient: linear-gradient
        (45deg, #f0f2f5, #9bb8e3, #d9dbde, #404c5e, #a7a9ab);
}

*,
*:focus,
*:hover {
    outline: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Rubik';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    background-image: var(--gradient);
    background-size: 400%;
    /* animation: bg-animation 20s infinite alternate; */

    scrollbar-width: thin;
}

@keyframes bg-animation {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

::-webkit-scrollbar {
    width: 8px;
    margin: 4px 4px 0;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 3px transparent;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #dddddd;
    border: solid 3px transparent;
    border-radius: 14px;
}

::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px #bbbbbb;
    border: solid 3px transparent;
    border-radius: 14px;
}
