/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-header {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content {
  border-radius: 16px;
}
.ant-modal-footer {
  padding: 16px 24px 24px 16px;
}
.ant-message-notice-content {
  border-radius: 16px;
}
.ant-tooltip-inner {
  border-radius: 8px;
}
