.Vpcs {
}

.VpcListOptions {
    border-radius: @border-radius-base-x2;
    margin: 0 15px 25px;
}

.VpcDropdownMenu {
    margin-right: 15px;
}

@hack: true; @import "/src/src/antd-default-theme.less";