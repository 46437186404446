.ServiceInformation {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.ServiceInformationMiddle {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.85;
}

.ServiceInformationBottomSection {
    display: flex;
    gap: 8px;
    font-size: 12px;
    align-items: center;
}

.ServiceInformationDsnBottom {
    padding: 0;
}

.ServiceInformationInstance {
    display: flex;
    align-items: center;
}

.ServiceInformationInstanceType {
    margin-left: 0.4rem;
}

.ServiceInformationStatusDot {
    align-items: center;
}

.BadgeIcon {
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
}

@hack: true; @import "/src/src/antd-default-theme.less";