.QueryStatsRadio {
    margin-bottom: 1.5rem;
}

.QueryStatsRadio > *:first-child {
    border-radius: @border-radius-base-x2 0 0 @border-radius-base-x2;
}

.QueryStatsRadio > *:last-child {
    border-radius: 0 @border-radius-base-x2 @border-radius-base-x2 0;
}

.QueryStatsExpanded > td {
    background-color: @table-header !important;
    color: @ccxBlack;
    font-weight: 600;
}

.QueryStatsExpanded + tr > td {
    border-top: 4px solid @ccxBlack;
    background-color: @ccxLighterGrayColor;

    &:hover {
        background-color: @ccxLighterGrayColor;
    }
}

.QueryStatsHeader thead > tr > th {
    text-align: left !important;
    padding: 0.5rem 1rem;
}

@hack: true; @import "/src/src/antd-default-theme.less";