.FeedbackFormPopover {
    min-width: 332px;
}
.FeedbackFormPopoverTitle {
    text-align: center;
    padding: 10px 0 0;
    position: relative;
}

.FeedbackFormPopoverClose {
    position: absolute;
    top: 5px;
    right: 2px;
}

.FeedbackFormTypeRadio {
    display: block;
    height: 30px;
    line-height: 30px;
}

.FeedbackFormTabs {
    width: 350px;
}

.FeedbackFormBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 2px 0 2px;
}

.FeedbackFormRadioGroup {
    margin-top: 20px;
    margin-left: 20px;
}

.FeedbackFormTextArea {
    height: 100%;
    resize: none;
    border: 0;
    padding: 0;
    border-radius: 0;
}

.FeedbackFormTabContent {
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.FeedbackFormFooter {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 15px 0 0 0;
}

.FeedbackFormThanks {
    padding: 0;

    .ant-result-icon {
        margin-bottom: 0 !important;

        .anticon {
            font-size: 42px !important;
        }
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";