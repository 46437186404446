.BackupsCollapse {
    background-color: transparent;
}

.BackupsCollapsePanel {
    border-bottom: 1px solid transparent;
}

.BackupsButtons {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 16px;
}

.DrawerFooterButtonsLeft {
    text-align: right;
}

.DrawerFooterButtonsRight {
    text-align: left;
}

.IconTextMargin4 {
    margin-left: 4px;
}

.BackupSettingsButton {
    display: flex;
    align-items: center;
}

@hack: true; @import "/src/src/antd-default-theme.less";