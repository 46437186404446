.DatastoreScalingStepHeader {
    margin-top: 1rem;
}

.DatastoreScalingStepHeaderInfo {
    color: rgba(0, 0, 0, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.DatastoreScalingStepHeaderSliderRow {
    justify-content: center;
}

@hack: true; @import "/src/src/antd-default-theme.less";