.BackupColumns {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-right: 16px;
    border-right: 1px solid @ccxGridTableRowBorderColor;
}

.BackupColumns > * {
    display: flex;
    flex-basis: '100%';
    flex-direction: row;
    overflow-x: hidden;
    gap: 8px;
    align-items: center;
}

.BackupColumnsLast {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

@hack: true; @import "/src/src/antd-default-theme.less";