.CcxLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.CcxLogo img {
    z-index: 2;
}

@hack: true; @import "/src/src/antd-default-theme.less";