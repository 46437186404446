.DataStore {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.DatastoreSkeleton {
    padding: 24px;
    background: @ccxWhite;
    border-radius: @border-radius-base-x2;
}

.DataStoreTabs > *:last-child {
    background: @ccxWhite;
    padding: 24px;
}

.DataStoreTabs {
    :global(.ant-tabs-content) {
        min-height: 70vh;
    }

    :global(.ant-tabs-tab) {
        padding: 8px 12px !important;
    }
}

.DataStoreBackRow {
    margin-top: 10px;
    button {
        width: 100%;
    }
}

.DataStoreTabs > div > div {
    overflow: visible !important;
}

.TabsBadge {
    position: absolute;
    z-index: 1;
    top: -10px;
    left: calc(100% - 14px);
}

.TabsStyle {
    display: flex !important;
    align-items: center !important;
}

.IconStyle {
    margin-right: 5px !important;
}
@media (max-width: 1220px) {
    .DataStore {
        width: calc(100vw - 40px);
    }
    :global(.ant-tabs-nav) {
        overflow-x: overlay;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";