.AddNewMemberModalInput {
    border-radius: @border-radius-base-x2;

    div {
        border-radius: @border-radius-base-x2 !important;
        display: flex;
    }
}

.MemberRoleSelect {
    :global(.ant-select-selector) {
        border-radius: @border-radius-base-x2 !important;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";