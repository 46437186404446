.WizardFormConfigurationStep1ConfigCard {
    padding: 1rem;
    min-height: 10rem;
}

.WizardFormConfigurationStep1ConfigOpt {
    padding: 0;
    margin-top: 0.3rem;
    color: rgba(0, 0, 0, 0.45);
}

.WizardFormConfigurationStep1ConfigCol {
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    margin-left: 0.5rem;
    min-width: 18rem;
    max-width: 19rem;
    border-radius: 16px;
    position: relative;
}

.WizardFormConfigurationStep1ConfigColWithHover {
    &:extend(.WizardFormConfigurationStep1ConfigCol);

    &:hover {
        border: 1px solid @ccxBaseBlue;
    }
}

.WizardFormConfigurationColSelected {
    border: 1px solid @ccxBaseBlue;
}

.WizardFormConfigurationStep1ConfigCol:nth-child(2) {
    // margin-right: 2rem;
}

.WizardFormConfigurationStep1ConfigRow {
    flex-direction: row;
}

.WizardFormConfigurationStep1MarkerGreen {
    background: #fafafa;
    position: absolute;
    padding: 2px 27px;
    left: 8rem;
    z-index: 5;
    color: #52c41a;
    top: -1rem;
    border: 1px solid #b7eb8f;
    border-radius: 1rem;
}

@media (max-width: 768px) {
    .WizardFormConfigurationStep1ConfigCol:nth-child(2) {
        margin-right: 0;
    }
}

.WizardFormConfigurationEmailNotification {
    margin-top: 12px;
    margin-bottom: 8px;
}

.WizardFormConfigurationAdvancedSettings {
    margin-top: 24px;
    margin-bottom: 8px;
    color: @primary-color;
}

.WizardFormConfigurationAdvancedSettingsButton {
    padding: 0;
    margin: 0;
}

@hack: true; @import "/src/src/antd-default-theme.less";