.ant-popover-buttons {
    display: flex !important;
}

.ant-popover-buttons .ant-btn {
    border-radius: 1.5rem !important;
    font-size: 0.75rem;
}

.DatastoreSettingDbParametersSelect .ant-select-selector {
    border-radius: 0 !important;
}

@hack: true; @import "/src/src/antd-default-theme.less";