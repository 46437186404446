.Members {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 16px;
    background-color: white;
    border-radius: 8px;
}

.MembersHeaderButton {
    margin-bottom: 16px;
    margin-top: 16px;
    margin-right: 16px;
}

.MembersTable {
    margin: 16px;
}

.MembersTag {
    border-radius: 6px;
}

@hack: true; @import "/src/src/antd-default-theme.less";