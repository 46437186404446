.MaintenanceTooltipPoints {
    padding-left: 24px;
}

.TooltipInfoIcon {
    margin-left: 4px;
}

:global(.ant-tooltip) {
    max-width: 680px;
}

@hack: true; @import "/src/src/antd-default-theme.less";