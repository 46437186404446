.AddServiceDatabaseVendorStepContainer {
    margin: 20px;

    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: @ccxBlack;
        text-align: center;
        margin-bottom: 30px;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";