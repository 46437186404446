.AppFlagIcon {
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

:global(.flag.size-l) {
    width: 30px;
    height: 18px;
}

@hack: true; @import "/src/src/antd-default-theme.less";