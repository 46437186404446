.FirewallsCollapse {
    background-color: transparent;
}

.FirewallsCollapsePanel {
    border-bottom: 1px solid transparent;
}

.FirewallsOptionsColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
    height: 30px;
    text-align: center;
    width: 30px;
}

.FirewallsTable {
    margin-top: 0.5rem;
}

@hack: true; @import "/src/src/antd-default-theme.less";