.AppContent {
    padding: 16px 30px;
    min-height: 85vh;
    display: flex;
    justify-content: center;
    background-color: @layout-body-background !important;
}

.AppContentContainer {
    width: 1280px;
}
@media (max-width: 1220px) {
    .AppContent {
        padding: 16px 8px;
        justify-content: start;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";