.DataStoreOverview {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.DataStoreOverviewSpace {
    width: 100%;
    padding-top: 16px;
}

@hack: true; @import "/src/src/antd-default-theme.less";