.InstanceVolumeTypeFormCentered {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    margin-top: 2px;
    height: fit-content;
}

.InstanceVolumeTypeFormInputNumber {
    width: 60%;
    border-radius: @border-radius-base-x2;
}

.InstanceVolumeTypeForm {
    input {
        border-radius: @border-radius-base-x2 !important;
    }

    :global(.ant-select-selector) {
        border-radius: @border-radius-base-x2 !important;
    }
}

.InstanceVolumeTypeCol {
    :global(.ant-form-item) {
        margin-bottom: 0px !important;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";