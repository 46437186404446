.DataStoreDetailsCard {
    background-color: #f5f5f5;
    padding: 16px;
    padding-bottom: 24px;
    border-radius: 4px;
}

.DataStoreViewLogsIcon {
    justify-content: center;
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
}

.LogInnerHeading {
    font-size: 16px;
    font-weight: 600;
}

.LogDetailsLabel {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    margin: 0;
}

.LogDetailsText {
    margin-bottom: 0;
}

.DownloadLogsButton {
    color: @primary-color;
}

.LogsDivider {
    margin: 16px 0;
    border: 1px solid #f0f0f0;
}

.LogsEntriesHeader {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
}

.HostsSelect {
    width: 200px;

    :global(.ant-select-selector) {
        border-radius: @border-radius-sm !important;
    }
}

.LogsEntriesTable {
    :global(.ant-pagination-item) {
        border-radius: @border-radius-sm;
    }

    :global(.ant-pagination-item-link) {
        border-radius: @border-radius-sm;
    }

    :global(.ant-select-selector) {
        border-radius: @border-radius-sm !important;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";