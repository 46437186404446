.PopoverContentRow {
    width: 250px;
    border-radius: @border-radius-base-x2;
}

.PopoverActionsRow {
    margin-top: 8px;
}

.ExclamationCircleFilledColor {
    color: @warning-color;
}

@hack: true; @import "/src/src/antd-default-theme.less";