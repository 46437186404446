.DatastoreScalingNodeInfoCardCol {
    border: 1px solid #d9d9d9;
    border-radius: @border-radius-base-x2;
    position: relative;
    min-height: 4rem;
}

.DatastoreScalingNodeInfoCardCol > div:first-child {
    width: 100%;
}

.DatastoreScalingNodeInfoCardRole {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 16px;
    position: absolute;
    padding: 2px 30px;
    right: 2rem;
    transform: translateY(-15px);
    z-index: 5;
}

.DatastoreScalingNodeInfoCardRoleSecondary {
    background: #f6ffed;
    color: #52c41a;
    border: 1px solid #b7eb8f;
    border-radius: 16px;
    position: absolute;
    padding: 2px 12px;
    right: 2rem;
    top: -11px;
    z-index: 5;
}

.DatastoreScalingNodeInfoCard {
    margin-bottom: 30px;
    main {
        padding: 18px 12px;
    }
}

.DatastoreScalingNodeName {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    column-gap: 5px;
}

.DatastoreScalingNodeIcon {
    display: flex;
    align-items: center;
    column-gap: 3px;
}

.DatastoreScalingSpecIcon {
    justify-content: center;
    display: flex;
    align-items: center;
}

.DatastoreScalingSpace {
    color: rgba(0, 0, 0, 0.45);
    margin-top: 2px;
}

.DatastoreScalingInstance {
    margin-left: 1.5rem;
}

.DatastoreScalingNodeCheckbox {
    visibility: hidden;
}

.DatastoreScalingNodeRemove {
    color: #ff4d4f;
    font-size: smaller;
    margin-right: 0.3rem;
}

.DatastoreScalingCheckbox {
    display: flex;
    align-items: center;
    gap: 0.375rem;
}

.DatastoreScalingCheckboxRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 3px;
}

.AppExistingNodesCard {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .DatastoreScalingNodeInfoCard:nth-child(odd) {
        padding-right: 8px;
    }

    .DatastoreScalingNodeInfoCard:nth-child(even) {
        padding-left: 8px;
    }
}

.AppNewNodesCard {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .DatastoreScalingNodeInfoCard:nth-child(odd) {
        padding-right: 8px;
    }

    .DatastoreScalingNodeInfoCard:nth-child(even) {
        padding-left: 8px;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";