.AppLabeledColComponent {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.AppLabeledRowComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;
}

.AppLabeledComponentLabelSoft {
    font-size: 14px;
    line-height: 22px;
    color: @ccxBlack;
    margin-bottom: 2px;
    opacity: 0.45;
}

.AppLabeledComponentLabel {
    font-size: 14px;
    line-height: 22px;
    color: @ccxBlack;
    margin-bottom: 2px;
}

.AppLabeledComponentChildren {
    font-weight: 700;
}

@hack: true; @import "/src/src/antd-default-theme.less";