.ConnectionAssistantFormStep2Title {
    margin: 20px 5px;
}

.ConnectionAssistantFormStep2Select {
    width: 40%;
    margin-bottom: 30px;

    div {
        border-radius: @border-radius-base-x2 !important;
    }
}
@media (max-width: 450px) {
    .ConnectionAssistantFormStep2Select {
        width: 100%;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";