.DataStoreSettingsMaintenanceDescription {
    margin-top: 8px;
    margin-bottom: 8px;
}

.DataStoreSettingsEmailNotificationLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.85;
}

@hack: true; @import "/src/src/antd-default-theme.less";