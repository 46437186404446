.PaymentMethod {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 0;
}

.PaymentMethod > * {
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.PaymentMethod > *:nth-child(1) {
    font-size: 12px;
    gap: 0;
}

.PaymentMethod > *:nth-child(2) {
    font-size: 12px;
    gap: 0;
}

.PaymentMethodLogo {
    margin-right: 5px;
}

@hack: true; @import "/src/src/antd-default-theme.less";