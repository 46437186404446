.Organizations {
    margin-bottom: 16px;
    margin-right: 16px;
    background-color: white;
    border-radius: 8px;
}

.OrganizationsHeaderButton {
    margin-bottom: 16px;
    margin-top: 16px;
    margin-right: 16px;
}

.OrganizationsTable {
    margin: 16px;
}

.MembersTag {
    border-radius: 6px;
}

@hack: true; @import "/src/src/antd-default-theme.less";