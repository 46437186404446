.DeploymentDashboardHostSelect {
    min-width: 200px;
    max-width: fit-content;
}

.DeploymentDashboardTabs {
    margin-top: 18px;
}

.DeploymentDashboardExtraTabContent {
    color: @ccxLightBlueColor;
    margin-right: 20px;
}

.DeploymentDashboardCustomTabBar {
    z-index: 1;
    padding-bottom: 5px;
    opacity: 1 !important;
}
@media (max-width: 400px) {
    :global(.ant-radio-button-wrapper) {
        padding: 0 10px;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";