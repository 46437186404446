.DeploymentCompareRangePickerCard {
    border-radius: @border-radius-base-x2;
}

.DeploymentCompareRangePickerCardContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DeploymentCompareRangePickerCardContentLeft {
    order: 1;
}

.DeploymentCompareRangePickerCardContentRight {
    order: 2;
}

.DeploymentCompareTabs {
    margin-top: 18px;
}

.DeploymentCompareExtraTabContent {
    color: @ccxLightBlueColor;
    margin-right: 20px;
    width: 300px;
}

.DeploymentCompareCustomTabBar {
    z-index: 1;
    padding-bottom: 5px;
    background-color: @ccxGrayLight;
    margin: 20px 0 10px !important;
}

.DeploymentCompareExtraTabContentSelect {
    display: grid;
}

@hack: true; @import "/src/src/antd-default-theme.less";