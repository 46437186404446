.DatastoreSettingDbParametersEditIcon {
    color: @primary-color;
    margin-left: 1rem;
    display: inline-flex;
    justify-content: flex-end;
}

.DatastoreSettingDbParametersName {
    margin-right: 0.3rem;
}

.DatastoreSettingDbParametersField {
    margin-bottom: 0;

    input,
    textarea {
        border-radius: @border-radius-base-x2;
    }
}

.DatastoreSettingDbParametersSelect {
    width: 10rem !important;
}

.DatastoreSettingDbParametersEdit {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DatastoreSettingDbParametersDefault {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}

.ParameterGroupAlertAction {
    align-self: center;
}

.ParameterGroupAlert {
    :global(.ant-alert-action) {
        align-self: center;
    }
    margin-bottom: 1rem;
}

.AddDBParametersButton {
    padding: 0.5rem;
    background-color: #f5f5f5;
}

.DatastoreDbParametersIcon {
    svg {
        height: 28px;
        width: 28px;
        color: #000000a6;
    }
}
.DbParametersDetails {
    padding-left: 8px;
}

.DBparametersGroupCard {
    width: 400px;
    background-color: #f5f5f5;
    padding: 0.5rem 1rem;
    border-radius: @border-radius-base-x2;
    p {
        margin: 0;
        color: #00000073;
    }
}

.DbParametersSelfCenter {
    align-self: center !important;
}

.DbParametersEnd {
    display: flex;
    justify-content: flex-end;
}

.DbParametersActions {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 800px) {
    .DbParametersGroupRow {
        flex-direction: column;
        align-items: start;
    }

    .DbParametersActions {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-start;
        align-self: start !important;
    }
}

.DbParameterSyncedRed {
    color: @ccxRed;
    width: 100px;
}

.DbParameterSyncedGreen {
    color: @ccxGreen;
    width: 100px;
}

.DbParametersGroupRow {
    margin-bottom: 24px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.DbParametersGroupSelect {
    :global(.ant-select-selector) {
        border-radius: @border-radius-base-x2 !important;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";