.DataStoreSettings {
    display: flex;
    flex-direction: column;
}

.DataStoreSettingsButtonContainer {
    display: flex;
    flex-direction: row-reverse;
    button {
        border-radius: @border-radius-base-x2;
        min-width: 120px;
        font-size: 14px;
        line-height: 22px;
    }
}

.DatastoreSettingsbuttons {
    display: flex;
    gap: 0.5rem;
}

@hack: true; @import "/src/src/antd-default-theme.less";