.WizardFormConfigurationStep6Coupon {
    display: flex;
    align-items: center;
    > div {
        flex-basis: 90%;
        margin-right: 10px;
    }

    > button {
        flex-basis: 10%;
        margin-top: 5px;
    }
}

.WizardFormConfigurationStep6CouponText {
    color: @ccxBaseBlue;
    cursor: pointer;
}

.WizardFormConfigurationStep6Title {
    margin-top: 30px;
}

.WizardFormConfigurationStep6Row {
    display: flex;
    justify-content: space-between;
}

.WizardFormConfigurationStep6SelectedCard {
    border: 2px solid @ccxBaseBlue;
    margin-bottom: 10px;
    cursor: pointer;
}

.WizardFormConfigurationStep6Card {
    margin-bottom: 10px;
    cursor: pointer;
}

.WizardFormConfigurationStep6NewCard {
    margin-top: 20px;
}

.WizardFormConfigurationStep6Flag {
    margin-right: 10px;
}

.WizardFormConfigurationStep6CreateUserButton {
    margin-top: 13px;
}

@hack: true; @import "/src/src/antd-default-theme.less";