.AppCodeCard {
    word-break: break-word;
    background-color: @ccxLighterGrayColor;

    code {
        font-size: 12px;
        line-height: 20px;
    }
}

.AppCodeCardCopyIcon {
    position: relative;
    font-size: 16px;
    padding: 0;
    color: @ccxDarkGrayColor;
    left: 10px;
}

.AppCodeCardCol {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

@hack: true; @import "/src/src/antd-default-theme.less";