.WizardFormConfigurationStep2RegionLabel {
    display: flex;
    flex-direction: column;
    line-height: 15px;
}

@media (min-width: 768px) {
    .ProviderRadioButton {
        margin-top: 4px;
    }
}

.ProviderLogo {
    max-height: 24px;
    width: 44px;
    margin-right: 8px;
    height: 100%;
    object-fit: contain;
}

.SelectedCloudProvider {
    border: 1px solid @primary-color !important;
}

.WizardFormConfigurationStep2RegionFlag {
    width: 50px;
}

@hack: true; @import "/src/src/antd-default-theme.less";